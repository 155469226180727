import Vue from 'vue'
import Router from 'vue-router'
import * as scrollFunctions from '~/mixins/scroll.functions.js'
import UserPage from '~/pages/user_page/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import ReviewList from '~/pages/review_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductVariant from '~/pages/product_variant/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
        path:'/user/',
        component:UserPage
      },
      {
        path:'/articles/authors/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"articles"}
      },
      {
        path:'/articles/authors/:autor/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"authors","href":"/articles/authors/"}]}
      },
      {
        path:'/articles/categories/:categoria/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articles/"}]}
      },
      {
        path:'/articles/tags/:etiqueta/:pag(\\d+)?',
        component:ArticleList,
        props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/articles/etiquetas/"}]}
      },
      {
        path:'/articles/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articles/"}]}
      },
      {
        path:'/articles/*index.amp.html',
        component:ArticlePag,
        meta:{"amp":true}
      },
      {
        path:'/articles/*',
        component:ArticlePag
      },
      {
        path:'/galleries/*.amp.html',
        component:GalleryPag,
        meta:{"amp":true}
      },
      {
        path:'/galleries/*.html',
        component:GalleryPag
      },
      {
        path:'/shoe-finder/index.amp.html',
        component:Recommender,
        meta:{"amp":true}
      },
      {
        path:'/shoe-finder/',
        component:Recommender
      },
      {
        path:'/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/authors/:pag(\\d+)?',
        component:AuthorList
      },
      {
        path:'/brands/',
        component:BrandList
      },
      {
        path:'/store/:tienda/*',
        component:StorePage
      },
      {
        path:'/*/questions/*-:id.html',
        component:InquiryPage,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"/Questions","href":"/running-shoes/questions/all/"}]}
      },
      {
        path:'/comments/articulos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/articulos/"}]}
      },
      {
        path:'/search/models/all/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"models","href":"/search/models/all/"}]}
      },
      {
        path:'/search/models/:marca/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"models","href":"/search/models/all/"}]}
      },
      {
        path:'/shoes/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1,50,62,64,84"}
      },
      {
        path:'/shoes/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/shoes/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/shoes/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}],"Type":1}
      },
      {
        path:'/comments/shoes/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/shoes/"}]}
      },
      {
        path:'/shoes/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/shoes/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/shoes/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/shoes/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/shoes/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/shoes/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/shoes/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/shoes/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Shoes","href":"/shoes/models/all/"}]}
      },
      {
        path:'/shoes/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"},
        meta:{"amp":true}
      },
      {
        path:'/shoes/*',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/running-shoes/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1"}
      },
      {
        path:'/running-shoes/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/running-shoes/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/running-shoes/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}],"Type":1}
      },
      {
        path:'/comments/running-shoes/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/running-shoes/"}]}
      },
      {
        path:'/running-shoes/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1"}
      },
      {
        path:'/running-shoes/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/running-shoes/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/running-shoes/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/running-shoes/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1"}
      },
      {
        path:'/running-shoes/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/running-shoes/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/running-shoes/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Running shoes","href":"/running-shoes/models/all/"}]}
      },
      {
        path:'/running-shoes/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1"},
        meta:{"amp":true}
      },
      {
        path:'/running-shoes/*',
        component:ProductPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"45"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}],"Type":1}
      },
      {
        path:'/comments/heart-rate-monitors-sports-watches/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/heart-rate-monitors-sports-watches/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"45"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"45"}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Heart Rate Monitors & Sports Watches","href":"/heart-rate-monitors-sports-watches/models/all/"}]}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"45"},
        meta:{"amp":true}
      },
      {
        path:'/heart-rate-monitors-sports-watches/*',
        component:ProductPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/sneakers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"50"}
      },
      {
        path:'/sneakers/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}],"Type":1}
      },
      {
        path:'/comments/sneakers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/sneakers/"}]}
      },
      {
        path:'/sneakers/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"50"}
      },
      {
        path:'/sneakers/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/sneakers/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"50"}
      },
      {
        path:'/sneakers/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/models/all/"}]}
      },
      {
        path:'/sneakers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"50"},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*',
        component:ProductPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/gym-trainers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"62"}
      },
      {
        path:'/gym-trainers/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/gym-trainers/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/gym-trainers/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}],"Type":1}
      },
      {
        path:'/comments/gym-trainers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/gym-trainers/"}]}
      },
      {
        path:'/gym-trainers/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"62"}
      },
      {
        path:'/gym-trainers/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/gym-trainers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/gym-trainers/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/gym-trainers/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"62"}
      },
      {
        path:'/gym-trainers/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/gym-trainers/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/gym-trainers/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Gym trainers","href":"/gym-trainers/models/all/"}]}
      },
      {
        path:'/gym-trainers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"62"},
        meta:{"amp":true}
      },
      {
        path:'/gym-trainers/*',
        component:ProductPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/crossfit-trainers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"64"}
      },
      {
        path:'/crossfit-trainers/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/crossfit-trainers/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/crossfit-trainers/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}],"Type":1}
      },
      {
        path:'/comments/crossfit-trainers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/crossfit-trainers/"}]}
      },
      {
        path:'/crossfit-trainers/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"64"}
      },
      {
        path:'/crossfit-trainers/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/crossfit-trainers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/crossfit-trainers/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/crossfit-trainers/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"64"}
      },
      {
        path:'/crossfit-trainers/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/crossfit-trainers/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/crossfit-trainers/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Crossfit trainers","href":"/crossfit-trainers/models/all/"}]}
      },
      {
        path:'/crossfit-trainers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"64"},
        meta:{"amp":true}
      },
      {
        path:'/crossfit-trainers/*',
        component:ProductPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/spinning-shoes/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"66"}
      },
      {
        path:'/spinning-shoes/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/spinning-shoes/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/spinning-shoes/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}],"Type":1}
      },
      {
        path:'/comments/spinning-shoes/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/spinning-shoes/"}]}
      },
      {
        path:'/spinning-shoes/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"66"}
      },
      {
        path:'/spinning-shoes/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/spinning-shoes/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/spinning-shoes/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/spinning-shoes/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"66"}
      },
      {
        path:'/spinning-shoes/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/spinning-shoes/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/spinning-shoes/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Spinning shoes","href":"/spinning-shoes/models/all/"}]}
      },
      {
        path:'/spinning-shoes/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"66"},
        meta:{"amp":true}
      },
      {
        path:'/spinning-shoes/*',
        component:ProductPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/fitness-trackers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"73"}
      },
      {
        path:'/fitness-trackers/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/fitness-trackers/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/fitness-trackers/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}],"Type":1}
      },
      {
        path:'/comments/fitness-trackers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/fitness-trackers/"}]}
      },
      {
        path:'/fitness-trackers/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"73"}
      },
      {
        path:'/fitness-trackers/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/fitness-trackers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/fitness-trackers/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/fitness-trackers/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"73"}
      },
      {
        path:'/fitness-trackers/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/fitness-trackers/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/fitness-trackers/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Fitness Trackers","href":"/fitness-trackers/models/all/"}]}
      },
      {
        path:'/fitness-trackers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"73"},
        meta:{"amp":true}
      },
      {
        path:'/fitness-trackers/*',
        component:ProductPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/smart-watches/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"74"}
      },
      {
        path:'/smart-watches/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smart-watches/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smart-watches/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}],"Type":1}
      },
      {
        path:'/comments/smart-watches/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/smart-watches/"}]}
      },
      {
        path:'/smart-watches/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"74"}
      },
      {
        path:'/smart-watches/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smart-watches/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smart-watches/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/smart-watches/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"74"}
      },
      {
        path:'/smart-watches/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smart-watches/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smart-watches/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smart watches","href":"/smart-watches/models/all/"}]}
      },
      {
        path:'/smart-watches/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"74"},
        meta:{"amp":true}
      },
      {
        path:'/smart-watches/*',
        component:ProductPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/bike-computers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"75"}
      },
      {
        path:'/bike-computers/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/bike-computers/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/bike-computers/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}],"Type":1}
      },
      {
        path:'/comments/bike-computers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/bike-computers/"}]}
      },
      {
        path:'/bike-computers/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"75"}
      },
      {
        path:'/bike-computers/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/bike-computers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/bike-computers/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/bike-computers/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"75"}
      },
      {
        path:'/bike-computers/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/bike-computers/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/bike-computers/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Bike computers","href":"/bike-computers/models/all/"}]}
      },
      {
        path:'/bike-computers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"75"},
        meta:{"amp":true}
      },
      {
        path:'/bike-computers/*',
        component:ProductPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/hiking-shoes/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"84"}
      },
      {
        path:'/hiking-shoes/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/hiking-shoes/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/hiking-shoes/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}],"Type":1}
      },
      {
        path:'/comments/hiking-shoes/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/hiking-shoes/"}]}
      },
      {
        path:'/hiking-shoes/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"84"}
      },
      {
        path:'/hiking-shoes/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/hiking-shoes/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/hiking-shoes/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/hiking-shoes/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"84"}
      },
      {
        path:'/hiking-shoes/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/hiking-shoes/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/hiking-shoes/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Hiking Shoes","href":"/hiking-shoes/models/all/"}]}
      },
      {
        path:'/hiking-shoes/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"84"},
        meta:{"amp":true}
      },
      {
        path:'/hiking-shoes/*',
        component:ProductPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/walking-boots/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1041"}
      },
      {
        path:'/walking-boots/comparison-tool/',
        component:ComparatorPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/walking-boots/comparison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/walking-boots/questions/recommendations/*',
        component:InquiryList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}],"Type":1}
      },
      {
        path:'/comments/walking-boots/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comments/walking-boots/"}]}
      },
      {
        path:'/walking-boots/reviews/testers/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1041"}
      },
      {
        path:'/walking-boots/reviews/testers/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/walking-boots/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/walking-boots/opinions/all/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/opinions/all/"}]}
      },
      {
        path:'/walking-boots/models/all/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/models/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/*/model/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1041"}
      },
      {
        path:'/walking-boots/*/amp/prices/all/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/walking-boots/*/prices/all/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/*/amp/prices/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]},
        meta:{"amp":true}
      },
      {
        path:'/walking-boots/*/prices/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Walking Boots","href":"/walking-boots/models/all/"}]}
      },
      {
        path:'/walking-boots/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1041"},
        meta:{"amp":true}
      },
      {
        path:'/walking-boots/*',
        component:ProductPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/sports-bras/outlet/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sports bras","href":"/sports-bras/outlet/all/"}]}
      },
      {
        path:'/sports-bras/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sports bras","href":"/sports-bras/outlet/all/"}]}
      },
      {
        path:'/gym-equipment/all/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Gym Equipment","href":"/gym-equipment/all/"}]}
      },
      {
        path:'/gym-equipment/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Gym Equipment","href":"/gym-equipment/all/"}]}
      },
      {
        path:'/discount-codes/',
        component:DiscountCodesList
      },
      {
        path:'/calculators/running-pace-calculator/',
        component:Calculator
      },
      {
        path:'/calculators/running-pace-calculator/marathon/',
        component:CalculatorMarathon
      },
      {
        path:'/calculators/running-pace-calculator/half-marathon/',
        component:CalculatorHalfMarathon
      },
      {
        path:'/calculators/running-pace-calculator/10-km/',
        component:Calculator10Km
      },
      {
        path:'/page-not-found',
        component:NotFoundPage
      },
      {
        path:'/404.html',
        component:NotFoundPage
      },
      {
        path:'/enlace/',
        component:RedirectingPag
      },
      {
        path:'/*/enlace/',
        component:RedirectingPag
      },
      {
        path:'/running-music/list',
        component:AudiosList
      },
      {
        path:'/running-music/playlist/:id',
        component:AudiosPage
      },
      {
        path:'/auth/login',
        component:AuthLogin
      },
      {
        path:'/auth/redirect',
        component:AuthRedirect
      },
      {
        path:'/auth/callback',
        component:AuthCallback
      },
      {
        path:'/auth/data',
        component:AuthData
      },
      {
        path:'/auth/newsletter',
        component:AuthNewsletter
      },
      {
        path:'/auth/remember',
        component:AuthRemember
      },
      {
        path:'/auth/password/:token',
        component:AuthPassword
      },
      {
        path:'/auth/signin',
        component:AuthSignin
      },
      {
        path:'/countries/:country',
        component:CountriesRedirect
      },
      {
        path:'/*',
        component:LandingPag
      }
    ]
  })
}
